import React, { useContext, useState } from 'react';
import { Typography, Button, Space, Tooltip } from 'antd';
import { FireFilled } from '@ant-design/icons';
import { ChromaContext } from '../../providers/ChromaProvider';
import PricingModal from './PricingModal';

const { Text } = Typography;

const TopSection = () => {
  const { credits } = useContext(ChromaContext);
  const [isModalVisible, setIsModalVisible] = useState(false);
  
  return (
    <div style={{
      backgroundColor: '#25262B',
      padding: '10px',
      borderRadius: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: 60,
      boxShadow: '0px 4px 6px rgba(0,0,0,0.3)',
      border: 'none'
    }}>
      <Space>
        <Tooltip title={`Kamu memiliki ${credits} token tersisa.`}>
          <FireFilled style={{ color: '#E8E8E8' }} />
        </Tooltip>
        <Text style={{ color: '#E8E8E8' }}>{credits}</Text>
      </Space>
      
      {/* Modified the Button to call handlePayment function when clicked */}
      <Button type="primary" id="pay-button" onClick={() => setIsModalVisible(true)}>Beli Token</Button>
      <PricingModal
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
      />
    </div>
  );
}

export default TopSection;
