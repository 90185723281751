import React from 'react';
import { Modal } from 'antd';

const AboutUsModal = ({ isVisible, onClose }) => {
  return (
    <Modal visible={isVisible} onCancel={onClose} footer={null}>
      <h2 ><strong>Siapa Kami?</strong></h2>
      <p>Halo! Kami adalah TetraML, kumpulan anak muda yang kebetulan suka mengutak-atik teknologi. 
        Jadi, kami bikin alat-alat yang berbasis kecerdasan buatan (AI) buat kita semua. 
        Kita percaya, AI bisa ngubah banget cara kita berinteraksi di masa sekarang. </p>
      <p>Produk pertama kami bernama Chroma, AI Art Generator berbasis Bahasa Indonesia! 
        Gokil, ye? Jadi buat kita-kita yang gabisa gambar atau design, kita tetep bisa bikin 
        gambar-gambar yang keren! TetraML nggak cuma berhenti di sini, kita mau bikin lebih 
        banyak lagi tools yang kece dan bermanfaat buat masyarakat Indonesia!
        Pantengin terus ya!</p>
      {/* More content */}
    </Modal>
  );
};

export default AboutUsModal;
