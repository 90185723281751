import React from 'react';
import '../components/font.css'

const VersionLabel = () => {
  const labelStyle = {
    position: 'absolute',
    top: '0px',
    right: '0px',
    backgroundColor: 'rgba(255, 255, 255, 0.2)', // semi-transparent background
    padding: '5px 10px',
    borderRadius: '0px 0px 0px 10px',
    color: '#FFC157',
    fontSize: '0.8rem',
    fontFamily: "'Kanit', sans-serif",
    fontWeight: '200',
    zIndex: 1000, // Ensure the label appears above other elements
  };

  return <div style={labelStyle}>CHROMA v1</div>;
};

export default VersionLabel;
