import React, {useContext} from 'react';
import { Menu, Dropdown, Button, Avatar, Typography } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { getAuth, signOut } from "firebase/auth";


import { UserContext } from '../../providers/UserProvider';
import { useNavigate } from 'react-router-dom';
import '../font.css'

const { Text } = Typography;

const BottomSection = () => {
    const { user, setUser } = useContext(UserContext);
    const navigate = useNavigate(); // Use useNavigate instead of useHistory
    const userName = (user?.displayName?.substring(0, 20) || 'User') + (user?.displayName?.length > 20 ? '...' : '');
    const userAvatar = user?.photoURL || 'Path to default avatar';

    // Logout function
  const handleLogout = () => {
    localStorage.removeItem('user'); // Remove user from local storage
    localStorage.removeItem('userData')
    setUser(null); // Update user state to null
    navigate('/'); // Navigate to landing page
  };

  // Dropdown menu
  const menu = (
    <Menu style={{backgroundColor: '#25262B', color: '#E8E8E8'}}>
      <Menu.Item key="1" onClick={handleLogout} style={{color: '#E8E8E8'}}>
        Keluar
      </Menu.Item>
    </Menu>
  );

  return (
    <div style={{
      // border: '1px solid #7A7A7A',
      padding: '10px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderRadius: 10,
      width: 230,
      // marginLeft: 10,
      // marginRight: 10,
      marginBottom: 5,
      height: 60,
      position: 'absolute',
      bottom: 5,
      backgroundColor: '#25262B',
      boxShadow: '0px 4px 6px rgba(0,0,0,0.3)', 
      border: 'none'
    }}>
        <div style={{ display: 'flex', alignItems: 'center', position: 'relative', overflow: 'hidden' }}>
          <Avatar src={userAvatar} style={{ marginRight: '8px' }} />
          <Text style={{ color: '#E8E8E8', fontWeight: '500', fontSize: '13px', fontFamily: "'Kanit', sans-serif" }}>{userName}</Text>
        </div>
        <Dropdown overlay={menu}>
          <Button icon={<SettingOutlined />} type="text" style={{ color: '#E8E8E8' }} />
        </Dropdown>
      </div>
  );
}

export default BottomSection;
