import React, { useContext, useEffect} from 'react';
import { Row, Col, Image, Carousel } from 'antd';
import { ChromaContext } from '../../providers/ChromaProvider';
import { getUserData } from '../../utils/APICaller'; // Adjust the import path according to your file structure
import { UserContext } from '../../providers/UserProvider';
import CustomImage from '../../utils/CustomImage.js'
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';


const MiddleSection = () => {
    const { gallery, setGallery, setCredits } = useContext(ChromaContext);
    const { user, getJwtToken, setUser} = useContext(UserContext);
    const userId = user?.uid || null;
    // const jwtToken = user?.jwtToken || 'errortoken';
    const auth = getAuth();
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            const fetchToken = async () => {
                if (user) {
                    const jwtToken = await user.getIdToken(true);

                    const userData = await getUserData({ jwtToken });

                    const imageUrls = userData.userChromaData?.gallery?.imageUrls || [];
                    const credits = userData.credits || 0;
                    setCredits(credits)
                    setGallery(imageUrls);
                } else {
                    navigate('/');
                }
            };
            fetchToken();
        });
        // Cleanup the subscription on component unmount
        return () => unsubscribe();
    }, [auth, navigate, setUser]);

    return (
        <div style={{ overflowY: 'auto', overflowX: 'hidden', width: '100%', marginTop: '10px', marginBottom: '10px', height: 'calc(100vh - 170px)', minHeight: 'calc(100vh - 170px)', maxHeight: 'calc(100vh - 170px)', alignSelf: 'center' }}>
            <Row gutter={16}>
                {[...gallery].reverse().map((url, index) => (
                    <Col key={index} span={8}>
                        <div style={{ padding: '8px' }}>
                            <CustomImage src={url} alt={`img-${index}`} style={{ width: '100%', borderRadius: '4px' }} hidePreviewLabel={true} />
                        </div>
                    </Col>
                ))}
            </Row>
        </div>
    );
}

const contentStyle = {
    margin: 0,
    height: '160px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
}

export default MiddleSection;
