import React from 'react';
import { Modal } from 'antd';

const FeatureModal = ({ isVisible, onClose }) => {
  return (
    <Modal visible={isVisible} onCancel={onClose} footer={null} width="70vw">
      <h2><strong>Features</strong></h2>
      <p>Chroma punya fitur keren loh, bisa bantu kalian yang mau buat gambar atau desain yang unik! Yuk, lihat fitur-fiturnya:</p>
      <ol>
        <li><strong>Mengubah Teks Jadi Gambar!</strong>
          <p>Kalian bisa bikin gambar hanya dengan masukkan teks dalam Bahasa Indonesia, gampang banget kan?</p>
        </li>

        <li><strong>Banyak Pilihan Gaya!</strong>
          <p>Mau gaya digital-art, cinematic, realism, anime, komik, atau lainnya? Tinggal pilih! Chroma siap membantu menciptakan karya seni impianmu!</p>
        </li>

        <li><strong>Pilih Ukuran Gambar!</strong>
          <p>Pilih ukuran gambar yang kamu mau, ada pilihan kotak, portrait (vertikal), atau landscape (horizontal), sesuaikan dengan kebutuhanmu!</p>
        </li>

        <li><strong>Mudah dan Praktis!</strong>
          <p>Cukup 1 token untuk generate satu gambar. Tenang, isi ulang token gampang banget dengan banyak pilihan metode pembayaran!</p>
        </li>
      </ol>
      <p>Yuk, cobain Chroma sekarang!</p>
    </Modal>
  );
};

export default FeatureModal;
