import React from 'react';
import { Image, Space } from 'antd';
import {
  DownloadOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  SwapOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import './CustomImage.css'; // Importing the custom styles.

const CustomImage = ({ src, width, hidePreviewLabel, ...restProps }) => {
  const onDownload = () => {
    const link = document.createElement('a');
    link.href = src;
    link.download = 'custom-image-name.png'; // Set the custom name here
    document.body.appendChild(link);
    link.click();
    link.remove();
};



  const customIconClass = hidePreviewLabel ? 'custom-icon' : ''; 

  return (
    <Image
      width={width}
      src={src}
      {...restProps}
      className={customIconClass} 
      preview={{
        ...restProps.preview,
        toolbarRender: (_, { actions: { onFlipY, onFlipX, onRotateLeft, onRotateRight, onZoomOut, onZoomIn }, transform: { scale } }) => (
          <Space size={24} className="custom-toolbar-wrapper">
            <ZoomInOutlined className="custom-toolbar-icon" disabled={scale === 50} onClick={onZoomIn} />
            <ZoomOutOutlined className="custom-toolbar-icon" disabled={scale === 1} onClick={onZoomOut} />
            <SwapOutlined className="custom-toolbar-icon" onClick={onFlipX} />
            <RotateLeftOutlined className="custom-toolbar-icon" onClick={onRotateLeft} />
            <RotateRightOutlined className="custom-toolbar-icon" onClick={onRotateRight} />
            <DownloadOutlined className="custom-toolbar-icon" onClick={onDownload} />
          </Space>
        ),
      }}
    />
  );
};

export default CustomImage;
