import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: ' AIzaSyBoLFfLvoyJWk8oEWGVbK_IIHLscGsRuKI ',
  authDomain: 'tetraml.firebaseapp.com',
  projectId: 'tetraml',
  // other config properties
};

const app = initializeApp(firebaseConfig);
