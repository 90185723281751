// App.js
import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from 'react-router-dom';
import LandingPage from './components/landingpage/LandingPage';
import Dashboard from './components/Dashboard'; 
import { UserProvider } from './providers/UserProvider';
import { ChromaProvider } from './providers/ChromaProvider';
import DimensionsProvider from './providers/DimensionsProvider'; // Adjust the import path as needed.
import { AuthProvider } from './providers/AuthProvider';
import './App.css';


function App() {
    return (
        <UserProvider>
            <ChromaProvider>
                <DimensionsProvider>
                    {/* <AuthProvider> */}
                    <Router>
                        <Routes>
                            <Route path="/" element={<LandingPage />} />
                            <Route path="/dashboard" element={<Dashboard />} />
                        </Routes>
                    </Router>
                    {/* </AuthProvider> */}
                </DimensionsProvider>
            </ChromaProvider>
        </UserProvider>
    );
}

export default App;
