import React, { useContext } from 'react';
import { Modal, Tabs, Button } from 'antd';
import { GoogleOutlined } from '@ant-design/icons';
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { UserContext } from '../../providers/UserProvider'; // adjust path as necessary
import { useNavigate } from 'react-router-dom';
import { signUp } from '../../utils/APICaller';
import '../../FirebaseConfig.js';

const { TabPane } = Tabs;

const AuthModal = ({ isOpen, onClose }) => {
  const auth = getAuth();
  const navigate = useNavigate();
  const { setUser } = useContext(UserContext);

  const signInWithGoogle = async () => {
    const userData = JSON.parse(localStorage.getItem('userData') || '{"credits": 0, "userChromaData": {"gallery": {"imageUrls": []}}}');
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = {
        displayName: result.user.displayName,
        email: result.user.email,
        photoURL: result.user.photoURL,
        uid: result.user.uid,
        emailVerified: result.user.emailVerified,
      };
      user.jwtToken = await result.user.getIdToken(true);

      await signUp({jwtToken: user.jwtToken});
      
      setUser(user); // this will also save user to localStorage via UserProvider useEffect
      
      onClose();
      navigate('/dashboard');
      
    } catch (error) {
      console.error("Google Sign In Error", error);
    }
  };

  return (
    <Modal open={isOpen} onCancel={onClose} footer={null} centered>
      <Tabs defaultActiveKey="login">
        <TabPane tab="Masuk" key="login">
            <Button icon={<GoogleOutlined />} onClick={signInWithGoogle} block>
              Masuk dengan Google
            </Button>
        </TabPane>
        
        {/* <TabPane tab="Sign Up" key="signup">
            <Button icon={<GoogleOutlined />} onClick={signInWithGoogle} block>
              Sign Up with Google
            </Button>
        </TabPane> */}
      </Tabs>
    </Modal>
  );
};

export default AuthModal;
