import React, { useState, useContext } from 'react';
import { Layout, Input, Select, Button, Typography, Card, Spin, Empty, message } from 'antd';
import { generateImage } from '../../utils/APICaller.js'
import { UserContext } from '../../providers/UserProvider.js';
import { ChromaContext } from '../../providers/ChromaProvider.js';
import 'antd/dist/reset.css';
import './MainSection.css'
import CustomImage from '../../utils/CustomImage.js';

const { Option } = Select;
const { TextArea } = Input;
const { Content } = Layout;
const { Text } = Typography;

function MainSection() {
  const [aspectRatio, setAspectRatio] = useState('square');
  const [prompt, setPrompt] = useState('');
  const [style, setStyle] = useState('digital-art');
  const [sample, setSample] = useState('1');
  const [imageUrls, setImageUrls] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const { user, getJwtToken } = useContext(UserContext);
  const { setCredits, credits, setGallery, gallery } = useContext(ChromaContext);
  const userId = user?.uid || 'defaultUserId';
  // const jwtToken = user?.jwtToken || 'errortoken';
  const displayName = user?.displayName || "default name";


  const shadowStyle = { boxShadow: '0px 4px 6px rgba(0,0,0,0.3)', border: 'none' };
  const imageStyle = { maxWidth: '100%', maxHeight: '300px', boxShadow: '0px 4px 6px rgba(0,0,0,0.1)', borderRadius: '8px' };


  const handleSubmit = async () => {
    if (credits < sample) {
      message.warning('Token kamu tidak cukup. Yuk isi ulang!');
      return;
  }
    if (!prompt) {
      message.warning("Teks tidak boleh kosong!")
      setHasError(true)
      return;
    }
    setIsLoading(true);
    if (aspectRatio == "Portrait") {
      setAspectRatio('portrait')
    }
    if (style == "--") {
      setStyle('no style')
    }

    try {
      const jwtToken = await getJwtToken()
      const urlList = await generateImage({ prompt, style, aspectRatio, sample, jwtToken })
      setImageUrls(urlList);
      setCredits(credits - sample)
      const newGallery = gallery.concat(urlList)

      if (gallery.length >= 30) {
        const toRemove = newGallery.length - 30;
        newGallery.splice(0, toRemove)
      }
      setGallery(newGallery)
    } catch (error) {
      message.error("Error, coba lagi nanti!")
      console.error('There was a problem with the fetch operation:', error);
    } finally {
      setIsLoading(false);
    }


  };

  return (
    <Layout style={{ padding: '16px', minHeight: '100vh', background: '#1A1B1E' }}>
      <Content style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div style={{ width: '80%', minHeight: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '16px', position: 'relative' }}>
          {
            isLoading ? (
              <Spin />
            ) : (
              imageUrls.length ? (
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                  {
                    imageUrls.slice(0, imageUrls.length).map((url, index) => (
                      <CustomImage src={url} alt={`Generated-${index}`} style={index === 1 ? { ...imageStyle, alignSelf: 'center' } : imageStyle} key={index} />
                    ))
                  }
                </div>
              ) : (
                <Card style={{ width: '60%', height: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#25262B', color: '#E8E8E8', ...shadowStyle }}>
                  {/* <Text type="secondary" style={{color: '#E8E8E8',}}>No Image Generated</Text> */}
                  <Empty description={<span style={{ color: '#E8E8E8', opacity: 0.5 }}>Tidak ada gambar</span>} />
                </Card>
              )
            )
          }
        </div>
        <TextArea
          placeholder="Tulis sesuatu…"
          value={prompt}
          onChange={(e) => {
            setPrompt(e.target.value);
            setHasError(false); // Reset error state when user starts typing
          }}
          rows={4}
          style={{
            width: '80%',
            marginBottom: '16px',
            ...shadowStyle,
            resize: 'none',
            backgroundColor: '#474B52',
            color: '#E8E8E8', // You may want to set a lighter color for text for readability
            borderColor: hasError ? 'red' : undefined
          }}
        />

        <div style={{ display: 'flex', flexDirection: 'row', width: '80%', gap: '16px', marginBottom: '16px' }}>
          {/* <Select
            value={style}
            onChange={setStyle}
            style={{
              flex: 1,
              ...shadowStyle,
              borderRadius: '8px',
              backgroundColor: '#474B52',
              color: '#E8E8E8'
            }}
            dropdownStyle={{ backgroundColor: '#474B52', color: '#E8E8E8' }} // Style for dropdown items
            placeholder="Style"
          >
            <Option value="digital-art">Seni Digital</Option>
            <Option value="realistic">Realistic</Option>
            <Option value="cinematic">Cinematic</Option>
            <Option value="anime">Anime</Option>
            <Option value="realism">Realism</Option>
            <Option value="photographic">Photographic</Option>
            <Option value="comic-book">Komik dan Ilustrasi</Option>
            <Option value="fantasy-art">Fantasi</Option>
            <Option value="analog-film">Foto Analog</Option>
            <Option value="neonpunk">Neonpunk</Option>
            <Option value="isometric">Isometri</Option>
            <Option value="lowpoly">Lowpoly</Option>
            <Option value="origami">Origami</Option>
            <Option value="watercolor">Cat Air</Option>
            <Option value="3d-model">Model 3D</Option>
            <Option value="photorealistic">Photorealistic</Option>
          </Select> */}

          <Select
            value={aspectRatio}
            onChange={setAspectRatio}
            style={{
              flex: 1,
              ...shadowStyle,
              borderRadius: '8px',
              backgroundColor: '#474B52',
              color: '#E8E8E8'
            }}
            dropdownStyle={{ backgroundColor: '#474B52', color: '#E8E8E8' }} // Style for dropdown items
            placeholder="Ukuran Gambar"
          >
            <Option value="square">Kotak</Option>
            <Option value="portrait">Portrait</Option>
            <Option value="landscape">Landscape</Option>
          </Select>
          <Select
            value={sample}
            onChange={setSample}
            style={{
              flex: 1,
              ...shadowStyle,
              borderRadius: '8px',
              backgroundColor: '#474B52',
              color: '#E8E8E8'
            }}
            dropdownStyle={{ backgroundColor: '#474B52', color: '#E8E8E8' }}
            placeholder="Jumlah Sampel"
          >
            <Option value="1">1 Sampel</Option>
            <Option value="2">2 Sampel</Option>
            <Option value="3">3 Sampel</Option>
          </Select>


          <Button type="primary" onClick={handleSubmit} disabled={isLoading} style={{ ...shadowStyle }}>Buat Gambar</Button>
        </div>
      </Content>
    </Layout>
  );
}

export default MainSection;
