import React from 'react';
import { Layout} from 'antd';
import BottomSection from './BottomSection';
import TopSection from './TopSection';
import MiddleSection from './MiddleSection';

const { Sider } = Layout;

const LeftPanel = () => {

  return (
    <Sider
      theme="dark"
      width={250}
      style={{
        height: '100vh',
        position: 'relative',
        boxSizing: 'border-box',
        background: 'linear-gradient(to right, #1F1B1B, #1C1818)',
        color: '#E8E8E8',
        padding: '10px',
        boxShadow: '0px 4px 70px rgba(0,0,0,0.6)', 
        border: 'none'
      }}
    >
      <TopSection/>
      {/* <WrapperSect/> */}
      <MiddleSection/>
      <BottomSection/>
    </Sider>
  );
};

export default LeftPanel;
