import React, { useContext, useEffect, useState } from 'react';
import { Layout } from 'antd';
import LeftPanel from './leftpanel/LeftPanel';
import MainSection from './mainsection/MainSection';
import VersionLabel from './VersionLabel';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { UserContext } from '../providers/UserProvider';

const Dashboard = () => {
  const navigate = useNavigate();
  const auth = getAuth();
  const { setUser } = useContext(UserContext)

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      const fetchToken = async () => {
        if (user) {
          const token = await user.getIdToken(true);
          // Ideally, manage token separately
          setUser({ ...user, jwtToken: token });
        } else {
          navigate('/');
        }
      };
      fetchToken();
    });
    // Cleanup the subscription on component unmount
    return () => unsubscribe();
  }, [auth, navigate, setUser]);

  // useEffect(() => {
  //     const to
  // }, [])


  return (
    <Layout style={{ minHeight: '100vh', position: 'relative' }}>
      <VersionLabel />
      <LeftPanel />
      <MainSection />
    </Layout>
  );
};

export default Dashboard;
