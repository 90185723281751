const baseUrl = "https://irsian-301013.et.r.appspot.com"
// const baseUrl = "http://localhost:8080"

export const generateImage = async ({prompt, style, aspectRatio, sample, jwtToken}) => {
    const url =  baseUrl + '/api/generate/'; // Replace with your API endpoint
    const payload = {
        prompt: prompt,
        style: style,
        aspect_ratio: aspectRatio,
        sample: sample
    };

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + jwtToken,
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data; // This will be the response body from the API
  } catch (error) {
    console.error('Fetch Error: ', error);
    throw error;
  }
}

export const getUserData = async ({jwtToken}) => {
    const url = baseUrl + "/users/me/userdata"
    try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + jwtToken,
          },
        });
    
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
    
        const data = await response.json();
        return data; // This will be the response body from the API
      } catch (error) {
        console.error('Fetch Error: ', error);
        throw error;
      }
}

export const topup = async ({jwtToken, nominal}) => {
  const url = baseUrl + "/users/me/topup"

  try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + jwtToken,
        },
        body: 20,
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const data = await response.json();
      return data; // This will be the response body from the API
    } catch (error) {
      console.error('Fetch Error: ', error);
      throw error;
    }
}

const midtransPay = () => {
  const midtransUrl = "https://app.sandbox.midtrans.com/snap/v1/transactions"
  const serverKey = "Mid-server-6W6gW-H-ifwhHw8X3-FYiIL4"


}

export const signUp = async ({jwtToken}) => {
  const url = baseUrl+ "/users/me/signup"
  try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + jwtToken,
        },
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.text();
      return data; // This will be the response body from the API
    } catch (error) {
      console.error('Fetch Error: ', error);
      throw error;
    }
}