import React, { useContext } from 'react';
import { Modal, Row, Col, Card } from 'antd';
import { UserContext } from '../../providers/UserProvider';
import '../../components/font.css'

function PricingOption({ tokens, price, onClick }) {
    const styles = {
        card: {
            textAlign: 'center',
            backgroundColor: '#DCE0E6'
        },
        tokenText: {
            fontSize: '24px',
            fontWeight: 'bold',
            color:'black',
            fontFamily: "'Kanit', sans-serif",
        },
        priceText: {
            fontSize: '16px',
            color: 'black',
            fontFamily: "'Kanit', sans-serif",
        }
    };

    return (
        <Col onClick={onClick} span={8}>
            <Card hoverable style={styles.card}>
                <p style={styles.tokenText}>{tokens}</p>
                <p style={styles.priceText}>{price}</p>
            </Card>
        </Col>
    );
}

export default function PricingModal({ visible, onClose }) {
    const { user, getJwtToken } = useContext(UserContext);

    const getRedirectUrl = async ({ nominal }) => {
        try {
            const jwtToken = await getJwtToken();
            const payload = {
                name: user.displayName,
                email: user.email,
                nominal: nominal
            };
            const url = "https://starlit-myth-400711.et.r.appspot.com/users/me/redirecturl"
            // const url = "http://localhost:8080/users/me/redirecturl"
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + jwtToken,
                },
                body: JSON.stringify(payload),
            });
            const data = await response.text();

            return data;
        } catch (error) {
            console.error('Error fetching snapToken:', error);
            return null;
        }
    }

    const handleOptionClick = async (optionValue) => {
        const nominalMapping = {
            1: 15000,
            2: 30000,
            3: 50000,
        };
    
        const nominal = nominalMapping[optionValue];
    
        const redirectUrl = await getRedirectUrl({ nominal });
        if (redirectUrl) {
            window.open(redirectUrl, '_blank');
    
            Modal.confirm({
                title: 'Konfirmasi Pembelian',
                content: 'Jika kamu telah menyelesaikan pembelian, silakan muat ulang halaman setelah untuk melihat saldo token yang diperbarui.',
                okText: 'Muat Ulang',
                cancelText: 'Tutup',
                onOk: () => {
                    window.location.reload();
                },
                onCancel: () => {
                    // Di sini Anda dapat menjalankan logika tambahan jika diperlukan
                    // ketika pengguna mengklik tombol 'Tutup'.
                }
            });
        }
    
        onClose();
    };
    

    const options = [
        { tokens: "25 Token", price: "Rp. 15.000", value: 1 },
        { tokens: "60 Token", price: "Rp. 30.000", value: 2 },
        { tokens: "130 Token", price: "Rp. 50.000", value: 3 }
    ];

    return (
        <Modal
            wrapClassName="custom-modal"
            visible={visible}
            footer={null}
            onCancel={onClose}
            width={800}
            centered
            bodyStyle={{ backgroundColor: 'rgba(255, 255, 255, 0.8)' }}
        >
            <Row gutter={16} justify="center">
                {options.map((opt, index) => (
                    <PricingOption
                        key={index}
                        tokens={opt.tokens}
                        price={opt.price}
                        onClick={() => handleOptionClick(opt.value)}
                    />
                ))}
            </Row>
        </Modal>
    );
}
