import React, { useState, useContext } from 'react';
import { Layout, Menu, Button } from 'antd';
import { UserContext } from '../../providers/UserProvider';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import AboutUsModal from './AboutUsModal';
import FeatureModal from './FeatureModal';
import AuthModal from './AuthModal'; // Adjust the import path if needed
import './NavBar.css'
import '../font.css'

const { Header } = Layout;

const Navbar = () => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate(); // Instantiate useNavigate
  const [isAboutUsVisible, setIsAboutUsVisible] = useState(false);
  const [isFeatureVisible, setIsFeatureVisible] = useState(false);
  const [isAuthVisible, setIsAuthVisible] = useState(false); // State for AuthModal visibility
  
  const handleMasukClick = () => {
    // const user = JSON.parse(localStorage.getItem('user') || 'null');
    if (user) {
      navigate('/dashboard'); // Navigate to dashboard if user is logged in
    } else {
      setIsAuthVisible(true); // Show AuthModal if user is not logged in
    }
  };
  
  return (
    <Header style={{
      position: 'fixed',
      width: '100%',
      zIndex: 1,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0 20px',
      background: 'transparent',
    }}>
      {/* <div style={{
        marginLeft: '40px',
        fontSize: '24px',
        fontWeight: 'bold',
        color: 'white',
        textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)'
      }}>TetraML</div> */}
      <img src='tetraml-logo.png' style={{height: '24px', marginLeft: '40px'}}/>
      
      <Menu mode="horizontal" theme="dark" style={{
        overflow: 'visible',
        width: '300px',
        background: 'transparent',
        borderColor: 'transparent',
        boxShadow: 'none',
        marginRight: '0'
      }}>
        <Menu.Item className="noActiveBackground" key="1" style={{ color: 'white', fontWeight: 'bold' }} onClick={() => setIsFeatureVisible(true)}>Fitur</Menu.Item>
        <Menu.Item className="noActiveBackground" key="2" style={{ color: 'white', fontWeight: 'bold' }} onClick={() => setIsAboutUsVisible(true)}>Tentang Kami</Menu.Item>
        <Menu.Item className="noActiveBackground" key="3">
          <Button type="primary" style={{ fontFamily: "'Kanit', sans-serif" }} onClick={handleMasukClick}>Masuk</Button>
        </Menu.Item>
      </Menu>
      
      <AboutUsModal isVisible={isAboutUsVisible} onClose={() => setIsAboutUsVisible(false)} />
      <FeatureModal isVisible={isFeatureVisible} onClose={() => setIsFeatureVisible(false)} />
      <AuthModal isOpen={isAuthVisible} onClose={() => setIsAuthVisible(false)} /> {/* AuthModal usage */}
    </Header>
  );
};

export default Navbar;
