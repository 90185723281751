import React, { useState, useContext } from 'react';
import { Button, Layout, Typography, Row, Col, Carousel, Tooltip } from 'antd';
import AuthModal from './AuthModal';
import { UserContext } from '../../providers/UserProvider';
import { useNavigate } from 'react-router-dom';
import Navbar from './NavBar';
import './LandingPage.css'

const { Content } = Layout;
const { Title } = Typography;

const LandingPage = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  const handleDashboardClick = () => {
    if (user) {
      navigate('/dashboard');
    } else {
      setIsModalVisible(true);
    }
  };

  return (
    <Layout style={styles.layout}>
      <div style={styles.overlay}></div>
      <Navbar />
      <Content style={styles.content}>
        <Row gutter={16} style={styles.row}>
          <Col span={12} style={styles.textContainer}>
            {/* <Title className="colorfulTitle" level={1} style={styles.title}>CHROMA by TetraML</Title> */}
            <div style={styles.titleContainer}>
              <Title level={1} style={styles.title}>CHROMA</Title>
              <Title level={2} style={styles.subtitle}>by TetraML</Title>
            </div>
            <Title level={3} style={styles.tagline}>Mengubah teks menjadi gambar menggunakan AI</Title>
            <Button type="primary" style={styles.button} onClick={handleDashboardClick}>Coba Sekarang</Button>
          </Col>
          <Col span={12}>
            <Carousel autoplay autoplaySpeed={2300}>
              <div style={styles.contentStyle} >
                {/* <img src="/deervangogh-digitalart.png" alt="Deer Van Gogh" style={styles.carouselImage}/> */}
                <Tooltip title="lukisan seekor rusa dengan style starry night van gogh, seni digital">
                  <img src="/deervangogh-digitalart.jpg" alt="Deer Van Gogh" style={styles.carouselImage} />
                </Tooltip>
              </div>
              <div style={styles.carouselImage}>
                <Tooltip title="potret seekor llama menggunakan kacamata hitam, style sinematik">
                  <img src="/llamasunglasses-cinematic.jpg" alt="Llama Sunglasses" style={styles.carouselImage} />
                </Tooltip>
                {/* <h3 style={{ width: '250px' }}>potret seekor llama menggunakan kacamata hitam, style sinematik</h3> */}
              </div>
              <div style={styles.contentStyle}>
                <Tooltip title="seekor paus dikelilingi ikan-ikan kecil, style anime">
                  <img src="/whalefishes-anime.jpg" alt="Fishes around whale" style={styles.carouselImage} />
                </Tooltip>
              </div>
              <div style={styles.contentStyle}>
                <Tooltip title="seorang ibu dan anaknya, style origami">
                  <img src="/motherandchild-origami.jpg" alt="Mother Origami" style={styles.carouselImage} />
                </Tooltip>
              </div>
            </Carousel>
          </Col>
        </Row>
        <AuthModal isOpen={isModalVisible} onClose={() => setIsModalVisible(false)} />
      </Content>
    </Layout>
  );
};

const styles = {
  layout: {
    position: 'relative',
    minHeight: '100vh',
    overflow: 'hidden',
    backgroundImage: 'url("/background.jpg")',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.65)',
  },
  content: {
    position: 'relative',
    paddingTop: '150px', // Adjusted to avoid overlapping with Navbar
  },
  row: {
    maxWidth: '1200px', // Limiting the maximum width of the row
    margin: '0 auto',
    padding: '0 10%',
    flex: '1',
    display: 'flex',
    alignItems: 'center',
  },
  textContainer: {
    // padding: '0 20px',
  },
  title: {
    color: '#FFC157',
    textShadow: '2px 2px 60px rgba(0, 0, 0, 0.5)',
    fontSize: '4.5rem',
    fontFamily: "'Kanit', sans-serif",
    fontWeight: '800',
    marginBottom: '0px',
  },
  tagline: {
    color: '#E8E8E8',
    textShadow: '1px 1px 60px rgba(0, 0, 0, 0.5)',
    fontSize: '1.75rem', // Slightly increased font size for better visibility
    fontFamily: "'Kanit', sans-serif",
    fontWeight: '600',
  },
  button: {
    fontFamily: "'Kanit', sans-serif",
    marginTop: '20px',
  },
  carouselContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  carouselImage: {
    width: '100%',
    maxHeight: '300px',
    objectFit: 'contain',
  },
  contentStyle: {
    margin: 0,
    height: '160px',
    maxHeight: '200px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
    height: '250px',
    width: '250px',

  },
  subtitle: {
    color: '#FEF9D1',
    textShadow: '2px 2px 60px rgba(0, 0, 0, 0.5)',
    fontSize: '3rem',
    fontFamily: "'Kanit', sans-serif",
    fontWeight: '800',
    marginBottom: '20px',
    marginTop: '0px',
  },
  titleContainer: {
    textAlign: 'left' // If you want to center the text.
  },
};

export default LandingPage;
