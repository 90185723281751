import React, { createContext, useState, useEffect, useCallback } from "react";
import { getAuth } from "firebase/auth";
// import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
// import { UserContext } from '../../providers/UserProvider.js';

const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    // Get user from localStorage when the app loads
    return JSON.parse(localStorage.getItem('user') || 'null');
  });

  const auth = getAuth();

  const getJwtToken = useCallback(async () => {
    if (auth.currentUser) {
      try {
        return await auth.currentUser.getIdToken(true);
      } catch (error) {
        console.error("Failed to get JWT token", error);
        return null;
      }
    }
    return null;
  }, [auth]);

  useEffect(() => {
    if (user) {
      // Set user in localStorage when the user state changes
      localStorage.setItem('user', JSON.stringify(user));
    } else {
      // Remove user from localStorage when user logs out
      localStorage.removeItem('user');
      localStorage.removeItem('userData')
    }
  }, [user]);

  return (
    <UserContext.Provider value={{ user, setUser, getJwtToken }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserProvider, UserContext };
