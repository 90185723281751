// DimensionsProvider.js
import React, { useState, useEffect } from 'react';

function DimensionsProvider({ children }) {
    const [dimensions, setDimensions] = useState({
        height: window.innerHeight,
        width: window.innerWidth,
    });

    const handleResize = () => {
        setDimensions({
            height: window.innerHeight,
            width: window.innerWidth,
        });
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    if (dimensions.width < dimensions.height) {
        return (
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                textAlign: 'center'
            }}>
                Maaf, Chroma by TetraML tidak dapat diakses gadget dengan dimensi kecil. Coba dengan laptop atau PC kamu!
            </div>
        );
    }

    return children;
}

export default DimensionsProvider;
