import React, { createContext, useState} from "react";

const ChromaContext = createContext();

const ChromaProvider = ({ children }) => {
  const [userData, setUserData] = useState(() => {
    // The default object is to avoid errors in case userData is null in localStorage.
    return JSON.parse(localStorage.getItem('userData') || '{"credits": 0, "userChromaData": {"gallery": {"imageUrls": []}}}');
  });

  // Get imageUrls and credits from userData
  const gallery = userData?.userChromaData?.gallery?.imageUrls || [];
  const credits = userData?.credits || 0;
  
  const setGallery = (newGallery) => {
    setUserData(prevData => ({
      ...prevData,
      userChromaData: {
        ...prevData.userChromaData,
        gallery: { imageUrls: newGallery }
      }
    }));
  };

  const setCredits = (newCredits) => {
    setUserData(prevData => ({
      ...prevData,
      credits: newCredits
    }));
  };

  return (
    <ChromaContext.Provider value={{ gallery, setGallery, credits, setCredits }}>
      {children}
    </ChromaContext.Provider>
  );
};

export { ChromaProvider, ChromaContext };
